import { translate } from '@/core/localization/translate';
export const {
  title,
  subtitle1,
  text1,
  subtitle2,
  text2,
  subtitle3,
  text3
} = translate({
  "title": "El ecommerce l\xEDder en bricolaje, casa y jard\xEDn de Europa",
  "subtitle1": "Millones de productos",
  "text1": "Muchos m\xE1s que en una tienda, y sin necesidad de recorrer pasillos y pasillos",
  "subtitle2": "Entrega a domicilio o en punto de recogida",
  "text2": "T\xFA eliges",
  "subtitle3": "Cientos de expertos en l\xEDnea",
  "text3": "Como tener a muchos Macgyver pero en l\xEDnea"
});