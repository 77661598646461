import classnames from 'classnames';

import { Image } from '@/core/Image/Image';
import { useUserTiming } from '@/core/observability/utils/useUserTiming';

import {
  subtitle1,
  subtitle2,
  subtitle3,
  text1,
  text2,
  text3,
  title,
} from './translations';

import styles from './reassurance.module.scss';

export interface ReassuranceProps {
  light?: boolean;
}

export const Reassurance = ({ light = false }: ReassuranceProps) => {
  useUserTiming('homepageB2C/reassurance');

  return (
    <div id="reassuranceContainer" className={styles.container}>
      <section
        className={classnames(
          styles.section,
          light ? styles.sectionLight : styles.sectionFull,
        )}
      >
        {!light && <h2 className={styles.title}>{title}</h2>}
        <ul className={styles.list}>
          <li className={styles.item}>
            <Image
              src="/assets/img/themes/default/illustrations/millions-of-products.svg"
              data-testid="MillionsOfProducts"
              alt=""
              loading="lazy"
              width={72}
              height={72}
            />
            <div className={styles.description}>
              {light ? (
                <p className={styles.subtitle}>{subtitle1}</p>
              ) : (
                <>
                  <h3 className={styles.subtitle}>{subtitle1}</h3>
                  <p className={styles.text}>{text1}</p>
                </>
              )}
            </div>
          </li>
          <li className={styles.item}>
            <Image
              src="/assets/img/themes/default/illustrations/home-delivery.svg"
              data-testid="HomeDelivery"
              alt=""
              loading="lazy"
              width={72}
              height={72}
            />
            <div className={styles.description}>
              {light ? (
                <p className={styles.subtitle}>{subtitle2}</p>
              ) : (
                <>
                  <h3 className={styles.subtitle}>{subtitle2}</h3>
                  <p className={styles.text}>{text2}</p>
                </>
              )}
            </div>
          </li>
          <li className={styles.item}>
            <Image
              src="/assets/img/themes/default/illustrations/online-experts.svg"
              data-testid="OnlineExperts"
              alt=""
              loading="lazy"
              width={72}
              height={72}
            />
            <div className={styles.description}>
              {light ? (
                <p className={styles.subtitle}>{subtitle3}</p>
              ) : (
                <>
                  <h3 className={styles.subtitle}>{subtitle3}</h3>
                  <p className={styles.text}>{text3}</p>
                </>
              )}
            </div>
          </li>
        </ul>
      </section>
    </div>
  );
};
